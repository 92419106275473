import { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { db } from "../firebase-config";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";

function ContactEmail(){
    
    var [email, setEmail] = useState('');
    var [warning, setWarning] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const emailCollectionRef = collection(db, "emailCollection");

    const createUser = async () => {
        await addDoc(emailCollectionRef, { email: email }).then(_ => {
            toast.dismiss();
            toast.success(`We have received your email, someone from our team will get in touch with you within 24 hours!`);
            setEmail("");
            setIsLoading(false);
        });
    };

    const submitForm = (e) => {
        setWarning("");
        setIsLoading(true);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(re.test(String(email).toLowerCase())) {
            createUser();
        } else {
            setWarning("Invalid Email address");
            setIsLoading(false);
        }
        e.preventDefault();
    }

    return(
        <div className="contactEmail">
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
            />
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12 left d-flex align-items-center justify-content-center">
                        <img src="/assets/images/emailUpdated.png" alt="email-icon" />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12 right d-flex align-items-end">
                        <form onSubmit={submitForm} style={{"max-width": "470px"}}>
                            <h1 data-aos="fade-down" data-aos-delay="100" data-aos-duration="800">Get in touch!</h1>
                            <h4 data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">Our sales team is ready to help from 9 AM-6 PM EDT. Our chat support is available 24/7</h4>
                            <div className="emailInput" data-aos="fade-up" data-aos-delay="100" data-aos-duration="800">
                                <input type="email" placeholder="Email" onChange={(e)=>{setEmail(e.target.value)}} value={email} name="" id="" />
                                <button type="submit">{
                                    isLoading ? (
                                        <div class="spinner-border" style={{
                                            "width": "23px",
                                            "height": "23px",
                                            "border-width": "2px"
                                        }} role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        "Send"
                                    )
                                }</button>
                            </div>
                            {warning != "" ?
                                <p style={{textAlign: 'center'}}><sub style={{color: 'red'}}>{warning}</sub></p>
                                :
                                null
                            }
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactEmail;