import Footer from './Footer';
import {Helmet} from "react-helmet";
import Navigation from './Navigation';
import {Route, Link, Routes, useLocation} from 'react-router-dom';

const PageNotFound = () => {
    return (
        <>
        <Navigation path='*' />
        <Helmet>
            <meta charSet="utf-8" />
            <title>XharpTech: An Award-Winning Agency | App Development | E-Commerce</title>
            <meta name="keywords" content="ui/ux design, app development, e-commerce, web design, website development" />
            <meta name="description" content="We are an award-winning design and development agency with a global portfolio. Our team specializes in UI/UX design and development, eCommerce, and many more. Let us help you create an amazing website or application." />            
            <meta property="og:image" itemprop="image" content="https://xharptech.com/assets/images/logo.png" />
        </Helmet>
        <div className="page-not-found">
            <section className="section-not-found">
                <div className="container">
                    <div className="text-center">
                        <h1>404 Not Found</h1>
                        <p>Cannot find what you are looking for? <Link to="/contact">
              Contact us
            </Link> for enquiries</p>
                        <img src="/assets/images/404-error-with-a-tired-person.svg" alt="404-error-with-a-tired-person" />
                    </div>
                </div>
            </section>
            <Footer />
        </div>
    </>
    )
}

export default PageNotFound;