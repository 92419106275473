import React, {useEffect, useState} from 'react';
import emailjs from "emailjs-com";
import Navigation from "./Navigation";
import ContactForm from "./ContactForm";
import ContactInfo from "./ContactInfo";
import Contact from "./Contact";
import ContactEmail from "./ContactEmail";
import Footer from "./Footer";
import ContactHero from "./ContactHero";
import {Helmet} from "react-helmet";
import Loader from './Loader';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactPage(){
    var [loader, setLoader] = useState('block');
    
    useEffect(() => {
        window.scrollTo(0, 0);
        setLoader('none');
    }, [])
    
    
    var bannerStyle = {
        backgroundImage: `url("/assets/images/banner-back.jpg")`,
        backgroundSize: '100% 100%',
        backgroundRepeat: 'no-repeat'
    }
    
    var [name, setName] = useState("");
    var [email, setEmail] = useState("");
    var [message, setMessage] = useState("");
    var [status, setStatus] = useState("asdasd");
    var [warning, setWarning] = useState("");
    const [sendBtnText, setSendBtnText] = useState('Send');
    const [isDisabled, setIsDisabled] = useState(false);

    function send(e){
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(name==="" || email === "" || message===""){
            setWarning("Please fill all the fields");
        }
        else{
            if(re.test(String(email).toLowerCase())){
                const templateParams = {
                    message: message,
                    from_name: name,
                    user_Email: email,
                    to_name: "XharpTech"
                };
                setSendBtnText("Sending");
                setIsDisabled(true)
                emailjs.send('service_rklssjm','template_11r5cjy', templateParams, '9RLRzgg3BfbvwurXn')
                .then((response) => {
                    if(response.status=="200") {
                        setEmail("");
                        setName("");
                        setMessage("");
                        setStatus("Message Sent!")
                        toast.dismiss();
                        toast.success(`We have received your email, someone from our team will get in touch with you within 24 hours!`);
                    }
                setSendBtnText("Send");
                setIsDisabled(false)
                },
                (err) => {
                    console.log('FAILED...', err);
                    setStatus("Something went wrong!");
                    toast.dismiss();
                    toast.error(`Something went wrong!`);
                    setSendBtnText("Send");
                    setIsDisabled(false)
                });
            } else {
                setWarning("Invalid Email address");
            }
        }
        e.preventDefault();
    }

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Contact Us | Meet XharpTech</title>
                <meta name="keywords" content="web design and development services, mobile development services, e-commerce development, top-quality results, industry experience" />
                <meta name="description" content="Our team specializes in providing top-quality web design and development, mobile development and e-commerce development services. We have a team of individuals who have exceptional skills through profound industry experience which result in high-quality results delivery." />
            </Helmet>
            <Navigation />
            <div className="contactPage">
                <ContactHero />
                <div className="container containerForm">
                    <div className="row mainRow">
                        <div className="col-lg-6 col-md-6 col-sm-12 left align-self-center">
                            <div className="container">
                                <div className="contactForm">
                                    <h1>Contact us</h1>
                                    <h3>We are here to help you! How can we help you?</h3>
                                    <div className="innerForm">
                                        <form onSubmit={send}>

                                        <input 
                                            type="text" 
                                            placeholder="Name *" 
                                            name="name" id="" 
                                            onChange={(e)=>{setName(e.target.value); setWarning(''); setStatus('');}} 
                                            value={name} 
                                            required 
                                        />
                                        <input 
                                            type="email" 
                                            placeholder="Email Address *" 
                                            onChange={(e)=>{setEmail(e.target.value); setWarning(''); setStatus('');}} 
                                            value={email}  
                                            name="" 
                                            id="" 
                                            required
                                        />
                                        <textarea name="message" placeholder="Your Message  *" 
                                        onChange={(e)=>{
                                            setMessage(e.target.value); 
                                            setWarning(''); 
                                            setStatus('');
                                        }} value={message} id="" cols="30" rows="10" required></textarea>
                                        <p><sub className="warning">{warning}</sub></p>
                                        <button type='submit' disabled={isDisabled} className="sendBut">
                                            {
                                                isDisabled ? (
                                                    <div class="spinner-border text-light" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                ) : (
                                                    sendBtnText
                                                )
                                            }
                                            
                                            </button>
                                        </form>
                                        <img src="/assets/images/or.png" alt="" />
                                        <a href='http://calendly.com/xharptech' target="_blank">
                                            <button className="sendBut">
                                                Book A Meeting
                                            </button>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 right align-self-center">
                            <div className="container">
                                <div className="up">
                                    <img src="/assets/images/contactImg.svg" alt="illustration" />
                                </div>
                                <div className="down">
                                    <div className="row">
                                        <div className="col-1">
                                            <h3><i class="fas fa-map-marker-alt"></i></h3>
                                        </div>
                                        <div className="col-11">
                                            <h4>Frankfurt (Germany), Karachi (Pakistan).</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <h3><i class="fas fa-envelope"></i></h3>
                                        </div>
                                        <div className="col-11">
                                            <h4>contact@xharptech.com</h4>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-1">
                                            <h3><i class="fas fa-phone-alt"></i></h3>
                                        </div>
                                        <div className="col-11">
                                            <h4>+92 305 4111759</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ContactEmail />
            <Footer />
        </>
    );
}

export default ContactPage;