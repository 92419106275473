import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CookieConsent from 'react-cookie-consent';
import { db } from "../firebase-config";
import {
    collection,
    getDocs,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
} from "firebase/firestore";

function Footer() {

    var d = new Date();
    var year = d.getFullYear();

    var [email, setEmail] = useState('');
    var [warning, setWarning] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const emailCollectionRef = collection(db, "emailCollection");

    const createUser = async () => {
        await addDoc(emailCollectionRef, { email: email }).then(_ => {
            toast.dismiss();
            toast.success(`You have successfully subscribed to our newsletter!`);
            setEmail("");
            setIsLoading(false);
        });
    };

    const submitForm = (e) => {
        setWarning("");
        setIsLoading(true);
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(email).toLowerCase())) {
            createUser();
        } else {
            setWarning("Invalid Email address");
            setIsLoading(false);
        }
        e.preventDefault();
    }

    return (
        <div className="footer">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-12 left" data-aos="fade-in" data-aos-delay="100" data-aos-duration="600">
                        <img src="/assets/images/footer-logo.svg" alt="" />
                        <p>Follow us</p>
                        <h5>
                            <a target="_blank" href='https://web.facebook.com/xharptech.official/' >
                                <img src="/assets/images/fb-vector.png" alt="" /></a>
                            <a target="_blank" href='https://twitter.com/XharpTech' ><img src="/assets/images/twitter-vector.png" alt="" /></a>
                            <a target="_blank" href='https://www.instagram.com/xharptech_official/' ><img src="/assets/images/instagram-vector.png" alt="" /></a>
                            <a target="_blank" href='https://www.linkedin.com/company/xharptech' ><img src="/assets/images/linkedin-vector.png" alt="" /></a>
                        </h5>
                        {/* <h5>
                            <a target="_blank" href='https://web.facebook.com/xharptech.official/' ><span className="facebook"><i class="fab fa-facebook-f"></i></span></a>
                            <a target="_blank" href='https://twitter.com/XharpTech' ><span className="twitter"><i class="fab fa-twitter"></i></span></a>
                            <a target="_blank" href='https://www.instagram.com/xharptech_official/' ><span className="instagram"><i class="fab fa-instagram"></i></span></a>
                            <a target="_blank" href='https://www.linkedin.com/company/xharptech' ><span className="linkedin"><i class="fab fa-linkedin-in"></i></span></a>
                        </h5> */}
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 mid" data-aos="fade-in" data-aos-delay="200" data-aos-duration="600">
                        <h3>We are located in</h3>
                        <div className="info">
                            <div className="row">
                                <div className="col-1 d-flex align-items-center">
                                    <p><i class="fas fa-map-marker-alt"></i></p>
                                </div>
                                <div className="col-11 d-flex align-items-center">
                                    <p>Frankfurt (Germany)<br /> Karachi (Pakistan)</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1 d-flex align-items-center">
                                    <p><i class="fas fa-phone-alt"></i></p>
                                </div>
                                <div className="col-11 d-flex align-items-center">
                                    <p>+92 305 4111759</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-1 d-flex align-items-center">
                                    <p><i class="fa fa-envelope"></i></p>
                                </div>
                                <div className="col-11 d-flex align-items-center">
                                    <p>contact@xharptech.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-12 right" data-aos="fade-in" data-aos-delay="300" data-aos-duration="600">
                        <h3>SOCIAL & NEWSLETTER</h3>
                        <h1>Get the latest news about DB</h1>
                        <form className="input-wrapper" onSubmit={submitForm}>
                            <input type="email" onChange={(e) => { setEmail(e.target.value) }} placeholder="Your email address" value={email} name="email" id="" />
                            <button type="submit">
                                {
                                    isLoading ? (
                                        <div class="spinner-border" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    ) : (
                                        <i class="fas fa-paper-plane"></i>
                                    )
                                }
                            </button>
                        </form>

                        {warning != "" ?
                            <p style={{ textAlign: 'center' }}><sub style={{ color: 'red' }}>{warning}</sub></p>
                            :
                            null
                        }
                    </div>
                </div>
                <div className="row bottom">
                    <hr />
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 left">
                            <h6>2022 © Digital XharpTech (Private) Limited</h6>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 right">
                            <ul>


                                <li>
                                    <Link to="/about">
                                        About Us
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/services">
                                        Services
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/our-portfolio">
                                        Portfolio
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact">
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <CookieConsent
                location="bottom"
                buttonText="I Agree"
                cookieName="xharptech"
                style={{ background: "#ffffff", fontWeight: "600", color: "#212529", boxShadow: "0px 0px 15px 2px rgba(0,0,0,0.05)", maxWidth: "80%", margin: "0 auto 20px auto", right: "0", border: "1px solid rgba(0, 194, 255, 0.2)" }}
                buttonStyle={{ background: "#212529", color: "#ffffff", fontWeight: "600", fontSize: "16px" }}
                expires={150}
            >
                This website uses cookies to enhance the user experience.{" "}
            </CookieConsent>
        </div>
    );
}

export default Footer;